import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Cookies</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Cookies</H5>
                <ScrollAnim.Link
                  to='o-que-e-um-cookie'
                  offsetTop='120'
                >
                  O que é um Cookie?
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='aceitacao-de-cookies'
                  offsetTop='120'
                >
                  Aceitação de Cookies:<br /> Regulamentação Vigente
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='tipos-de-cookies'
                  offsetTop='120'
                >
                  Tipos de Cookies
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-tecnicos'
                  offsetTop='120'
                >
                  Cookies técnicas
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-personalizados'
                  offsetTop='120'
                >
                  Cookies de personalização
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-analise'
                  offsetTop='120'
                >
                  Cookies de análise
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-publicidade'
                  offsetTop='120'
                >
                  Cookies de publicidade
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-publicidade-comportamental'
                  offsetTop='120'
                >
                  Cookies de publicidade<br /> comportamental
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-terceiros'
                  offsetTop='120'
                >
                  Cookies de terceiros
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-redes-sociais'
                  offsetTop='120'
                >
                  Cookies De Redes Sociais
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='cookies-de-google-analytics'
                  offsetTop='120'
                >
                  Cookies De Google Analytics
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='outras-especificacoes-de-cookies'
                  offsetTop='120'
                >
                  Outras Especificações de cookies
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='possiveis-cookies-de-terceiros'
                  offsetTop='120'
                >
                  Possíveis Cookies de terceiros
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='gerir-e-recusar-o-uso-de-cookies'
                  offsetTop='120'
                >
                  Gerir e Recusar o uso de cookies
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='mais-informacoes-sobre-cookies'
                  offsetTop='120'
                >
                  Mais informações sobre cookies
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph>Você está no lugar certo para saber mais sobre o uso de cookies do site da HostDime Brasil. A seguir vamos explicar o que são os cookies, quais os tipos de cookies utilizados, para quais finalidades e como você pode exercer o seu direito para configurar seu navegador e rejeitar o uso de qualquer uma delas.</Paragraph>
                  <Paragraph>Por isso, é preciso reforçar que se você decidir não usar alguns cookies, este site pode não funcionar perfeitamente, afetando a sua experiência de usuário.</Paragraph>
                </Div>
                <Div id='o-que-e-um-cookie'>
                  <H6 style={{ marginTop: 24 }}>O QUE É UM COOKIE?</H6>
                  <Paragraph>Um cookie é um arquivo de texto que é baixado em seu computador ao acessar determinadas páginas da web ou blogs.</Paragraph>
                  <Paragraph>Os cookies permitem a essa página, entre outras coisas, armazenar e recuperar informação sobre os seus hábitos de navegação ou de sua equipe, e de acordo com a informação que contenha e do modo como você usa seu computador, podem ser utilizadas para lhe reconhecer.</Paragraph>
                  <Paragraph>O navegador do usuário memoriza cookies no disco rígido apenas durante a sessão atual, ocupando um espaço de memória mínima e não prejudicial ao computador. Os cookies não contêm nenhum tipo de informação pessoal específica e a maioria delas são apagadas do disco rígido ao encerrar a sessão do navegador (chamados cookies de sessão).</Paragraph>
                  <Paragraph>A maioria dos navegadores aceita como padrão os cookies e, com a independência das mesmas, permitem ou impedem as definições de segurança cookies temporários ou memorizadas.</Paragraph>
                  <Paragraph>Os cookies associam-se ao navegador, não a pessoa, por isso não armazenam informações sensíveis sobre você como cartões de crédito ou dados bancários, fotografias, informações pessoais e etc.</Paragraph>
                  <Paragraph>Os dados que guardam são de caráter técnico, estatísticos, preferências pessoais e personalização de conteúdo.</Paragraph>
                </Div>
                <Div id='aceitacao-de-cookies'>
                  <H6 style={{ marginTop: 24 }}>ACEITAÇÃO DE COOKIES: REGULAMENTAÇÃO VIGENTE</H6>
                  <Paragraph>Ao acessar este site, e de acordo com a normativa vigente em matéria de proteção de dados, informamos o uso de cookies, dando-lhe a opção de aceitar expressamente e de ter acesso a mais informações através desta Política de Cookies.</Paragraph>
                  <Paragraph>Você deve saber que no caso de continuar navegando, você está dando seu consentimento para a utilização de cookies. Mas a qualquer momento pode mudar de opinião e bloquear a sua utilização através de seu navegador.</Paragraph>
                  <Paragraph>Para sua total tranquilidade, este site está em conformidade com o estipulado na legislação em vigor em relação com o uso de cookies e de seus dados pessoais:</Paragraph>
                  <ul>
                    <li>&#9679; Lei Brasileira n.o 13.709, de 2018 - Lei Geral de Proteção de Dados Pessoais;</li>
                    <li>&#9679; Lei Brasileira n.o 12.965, de 2014 - Marco Civil da Internet;</li>
                    <li>&#9679; <a href='https://op.europa.eu/pt/publication-detail/-/publication/3e485e15-11bd-11e6-ba9a-01aa75ed71a1' target='_blank'>RGPD (Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas físicas)​, que é a nova legislação daUnião Europeia que unifica a regulamentação do tratamento dos dados pessoais nos diferentes países da UE.</a></li>
                  </ul>
                  <Paragraph>Esta Política de Cookies pode ser alterada a qualquer momento para se adaptar às novidades legislativas ou mudanças em nossas atividades, sendo vigente a cada momento a política que está publicada no site. Em caso de alterações avisaremos no site.</Paragraph>
                </Div>
                <Div id='tipos-de-cookies'>
                  <H6 style={{ marginTop: 24 }}>TIPOS DE COOKIES</H6>
                  <Paragraph>Para oferecer uma melhor experiência do usuário, para obter dados analíticos, armazenar e recuperar informação acerca dos seus hábitos de navegação ou de sua equipe e desenvolver a sua atividade, o site da HostDime Brasil utiliza cookies próprias e de terceiros.</Paragraph>
                  <Paragraph>Quais os tipos de cookies são utilizados neste site?</Paragraph>
                </Div>
                <Div id='cookies-tecnicos'>
                  <H6 style={{ marginTop: 24 }}>Cookies técnicas</H6>
                  <Paragraph>São aqueles que permitem ao usuário a navegação através de uma página web, plataforma ou aplicação e a utilização das diferentes opções ou serviços que nela existam, como por exemplo: controlar o trânsito e a comunicação de dados, identificar a sessão, acessar partes de acesso restrito, recordar os elementos que integram um pedido, realizar o processo de compra de um pedido, realizar o pedido de inscrição ou participação em um evento, usar itens de segurança durante a navegação, armazenar conteúdos para a difusão de vídeos ou som ou compartilhar conteúdo através das redes sociais.</Paragraph>
                </Div>
                <Div id='cookies-personalizados'>
                  <H6 style={{ marginTop: 24 }}>Cookies de personalização</H6>
                  <Paragraph>Estes cookies permitem ao utilizador acessar ao serviço com algumas características de caráter geral predefinidas em função de uma série de critérios, como por exemplo o idioma,o tipo de navegador através do qual acessa o serviço, a localidade de onde acessa ao serviço, etc.</Paragraph>
                </Div>
                <Div id='cookies-de-analise'>
                  <H6 style={{ marginTop: 24 }}>Cookies de análise</H6>
                  <Paragraph>São aqueles tratados por nós ou por terceiros, que nos permitem quantificar o número de usuários, e assim realizar a medição e a análise estatística da utilização que os usuários fazem do serviço oferecido. Para isso, ele analisa a sua navegação em nosso site, com o fim de melhorar a oferta de produtos ou serviços que lhe oferecemos.</Paragraph>
                </Div>
                <Div id='cookies-de-publicidade'>
                  <H6 style={{ marginTop: 24 }}>Cookies de publicidade</H6>
                  <Paragraph>Os Cookies tratados por nós ou por terceiros, nos permitem gerir da forma mais eficaz possível a oferta de espaços publicitários na página web, adequando o conteúdo do anúncio ao conteúdo do serviço solicitado ou ao uso que se faça de nossa página web.</Paragraph>
                  <Paragraph>Para isso podemos analisar seus hábitos de navegação na Internet e podemos mostrar publicidade relacionada com o seu perfil de navegação.</Paragraph>
                </Div>
                <Div id='cookies-de-publicidade-comportamental'>
                  <H6 style={{ marginTop: 24 }}>Cookies de publicidade comportamental</H6>
                  <Paragraph>São aqueles que permitem a gestão, da forma mais eficiente possível, dos espaços publicitários em uma página da web, aplicação ou plataforma a partir da qual presta o serviço solicitado.</Paragraph>
                  <Paragraph>Estes cookies armazenam informação do comportamento dos usuários, obtidas através da observação contínua de seus hábitos de navegação, o que permite desenvolver um perfil específico para exibir publicidade em função do mesmo.</Paragraph>
                </Div>
                <Div id='cookies-de-terceiros'>
                  <H6 style={{ marginTop: 24 }}>Cookies de terceiros</H6>
                  <Paragraph>
                    RD Station. Confira a <a href='https://resultadosdigitais.com.br/legal.rdstation.com/pt-br/privacy-policy/' target='_blank'>Política de Privacidade.</a><br />   
                    HubSpot. Confira a <a href='https://legal.hubspot.com/br/privacy-policy' target='_blank'>Política de Privacidade.</a><br />
                    HotJar. Confira a <a href='https://www.hotjar.com/legal/policies/privacy/' target='_blank'>Política de Privacidade</a>.
                  </Paragraph>
                </Div>
                <Div id='cookies-de-redes-sociais'>
                  <H6 style={{ marginTop: 24 }}>Cookies De Redes Sociais</H6>
                  <Paragraph>Cookies de redes sociais podem ser armazenados no seu navegador enquanto você navega pelo blog, por exemplo, quando você usa o botão de compartilhar um artigo da HostDime Brasil em alguma rede social.</Paragraph>
                  <Paragraph>As empresas que geram esses cookies correspondentes às redes sociais que utilizam este blog têm suas próprias políticas de cookies:</Paragraph>
                  <ul>
                    <li>&#9679; Cookie do Twitter, segundo o disposto em sua <a href='https://twitter.com/pt/privacy' target='_blank'>Política de privacidade e de uso de cookies.</a></li>
                    <li>&#9679; Cookie do LinkedIn, segundo o disposto em sua <a href='https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies' target='_blank'>Política de cookies.</a></li>
                    <li>&#9679; Cookie de Facebook, de acordo com o disposto em sua <a href='https://www.facebook.com/policies/cookies/' target='_blank'>Política de cookies.</a></li>
                    <li>&#9679; Cookie do YouTube, de acordo com o disposto em sua <a href='https://policies.google.com/technologies/cookies?hl=pt-BR' target='_blank'>Política de cookies.</a></li>
                    <li>&#9679; Cookie de Instagram, segundo o disposto em sua <a href='https://help.instagram.com/1896641480634370' target='_blank'>Política de cookies.</a></li>
                  </ul>
                  <Paragraph>Assim, as implicações de privacidade serão em função de cada rede social e dependem da configuração de privacidade que tenha nessa rede.</Paragraph>
                  <Paragraph>Em nenhum caso, nem a HostDime Brasil, nem os anunciantes, podem obter informações de identificação pessoal de cookies.</Paragraph>
                </Div>
                <Div id='cookies-de-google-analytics'>
                  <H6 style={{ marginTop: 24 }}>Cookies De Google Analytics</H6>
                  <Paragraph>Em particular, este site utiliza o Google Analytics, um serviço de análise web fornecido pela Google, Inc. com sede nos Estados Unidos, com sede em 1600 Amphitheatre Parkway, Mountain View, ca 94043.</Paragraph>
                  <Paragraph>Para a prestação destes serviços, a Google utiliza cookies que coletam informações, incluindo o endereço IP do usuário, que será transmitida, tratada e armazenada pelo Google nos termos fixados na web Google.com. Isso inclui a possível transmissão de tais informações a terceiros por motivos de exigência legal ou quando estes terceiros processem a informação por conta do Google.</Paragraph>
                  <Paragraph>Para consultar o tipo de cookie utilizado pelo Google, além do cookie do Google+ e Google Maps na url abaixo:</Paragraph>
                  <ul>
                    <li>&#9679; <a href='https://policies.google.com/technologies/types?hl=pt-br' target='_blank'>https://policies.google.com/technologies/types?hl=pt-br</a></li>
                  </ul>
                </Div>
                <Div id='outras-especificacoes-de-cookies'>
                  <H6 style={{ marginTop: 24 }}>Outras Especificações de cookies</H6>
                  <Paragraph>Personalização: estes cookies me ajudam a lembrar com que pessoas ou sites você interagiu, para que possa mostrar conteúdo relacionado.</Paragraph>
                  <Paragraph>Preferências: estes cookies me permitem recordar as suas definições e preferências, como o seu idioma preferido, e sua configuração de privacidade.</Paragraph>
                  <Paragraph>Segurança: estes cookies são para evitar criar riscos de segurança. Principalmente para detectar quando alguém está tentando invadir sua conta.</Paragraph>
                </Div>
                <Div id='possiveis-cookies-de-terceiros'>
                  <H6 style={{ marginTop: 24 }}>Possíveis Cookies de terceiros</H6>
                  <Paragraph><span>O Google Tag Manager:</span> o Google Tag Manager é um sistema de gestão de etiquetas que permite atualizar, de forma rápida e fácil, as etiquetas e os fragmentos de código do site ouaplicativo móvel. Uma vez que o fragmento de Tag Manager foi adicionado a um site ou aplicativo móvel, você pode configurar as etiquetas através de uma interface de usuário baseada na Web, sem ter que mudar e implementar o código adicional. Deste modo, reduz-se o número de erros e evita-se recorrer a um programador ou desenvolvedor para fazer mudanças.</Paragraph>
                  <Paragraph>Para mais informações <a href='https://policies.google.com/privacy?hl=pt-BR' target='_blank'>clique aqui.</a></Paragraph>
                  <Paragraph><span>GA Audiences:</span> recolhem o comportamento dos usuários no site e os agrupa em audiências de remarketing com base nos critérios que definem. São utilizados principalmente em campanhas de remarketing do google Ads em redes de pesquisa e Display. Para mais informações <a href='https://policies.google.com/privacy?hl=pt-BR' target='_blank'>clique aqui</a></Paragraph>
                  <Paragraph><span>O Google Ads Conversion:</span> trata-se do pixel, que recolhe as conversões do Google Ads. Para mais informações <a href='https://policies.google.com/privacy?hl=pt-BR' target='_blank'>clique aqui.</a></Paragraph>
                  <Paragraph><span>O Google Ads User List:</span> Igual ao GA Audiencies, mas permite criar listas de remarketing do google Ads diretamente. Para mais informações <a href='https://policies.google.com/privacy?hl=pt-BR' target='_blank'>clique aqui.</a></Paragraph>
                  <Paragraph><span>Google Dynamic Remarketing:</span> é uma etiqueta que permite fazer remarketing para os visitantes de nosso site na rede de Display do Google com anúncios que contêm produtos que já vimos. para mais informações <a href='https://policies.google.com/privacy?hl=pt-BR' target='_blank'>clique aqui.</a></Paragraph>
                  <Paragraph><span>Facebook Custom Audience:</span> recolhe o comportamento dos usuários no site e os agrupa em audiências de remarketing com base nos critérios que definem. Para mais informações <a href='https://www.facebook.com/policies/cookies/' target='_blank'>clique aqui</a>.</Paragraph>
                  <Paragraph><span>Facebook Pixel:</span> Facebook Pixel é uma ferramenta de análise que nos ajuda a medir a eficácia da nossa publicidade e na elaboração de relatórios das ações que as pessoas fazem quando visitam o site. Para mais informações <a href='https://www.facebook.com/policies/cookies/' target='_blank'>clique aqui</a>.</Paragraph>
                  <Paragraph><span>Facebook Impression:</span> permite rastrear as impressões em campanhas CPM. Para mais informações <a href='https://www.facebook.com/policies/cookies/' target='_blank'>clique aqui</a>.</Paragraph>
                  <Paragraph><span>Facebook Connect:</span> permite navegar na Internet com a sua identidade de usuário. Assim, um usuário pode comentar e compartilhar conteúdo de outros sites e toda esta atividade aparecerá no seu mural. No nosso site é feito através do botão de Compartilhar no fichas de produto e outras páginas da web. Para mais informações <a href='https://www.facebook.com/policies/cookies/' target='_blank'>clique aqui</a>.</Paragraph>
                </Div>
                <Div id='gerir-e-recusar-o-uso-de-cookies'>
                  <H6 style={{ marginTop: 24 }}>GERIR E RECUSAR O USO DE COOKIES</H6>
                  <Paragraph>Em qualquer momento, você pode adaptar a configuração do navegador para gerenciar, rejeitar o uso de cookies e ser notificado antes que sejam baixados.</Paragraph>
                  <Paragraph>Você também pode adaptar a configuração do navegador para recusar todos os cookies ou apenas os cookies de terceiros. E você também pode remover qualquer um dos cookies que já se encontram em seu computador.</Paragraph>
                  <Paragraph>Para isso, você deve ter em mente que você terá que adaptar separadamente a configuração de cada navegador e computador que você use uma vez que, como já comentado anteriormente, as cookies se associam ao navegador, não a pessoa.</Paragraph>
                  <ul>
                    <li>&#9679; Google Chrome <a href='https://support.google.com/chrome/answer/95647?hl=pt-BR' target='_blank'>https://support.google.com/chrome/answer/95647?hl=pt-BR</a></li>
                    <li>&#9679; Internet Explorer <a href='https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10'>https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-10</a></li>
                    <li>&#9679; Mozilla Firefox <a href='https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam' target='_blank'>https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</a></li>
                    <li>&#9679; Apple Safari <a href='https://support.apple.com/pt-br/HT201265' target='_blank'>https://support.apple.com/pt-br/HT201265</a></li>
                  </ul>
                </Div>
                <Div id='mais-informacoes-sobre-cookies'>
                  <H6 style={{ marginTop: 24 }}>Mais informações sobre cookies</H6>
                  <Paragraph>Pode consultar o regulamento sobre o uso de cookies e obter mais informações: <a href='http://www.aboutcookies.org/' target='_blank'>http://www.aboutcookies.org/</a>.</Paragraph>
                  <Paragraph>E se você deseja ter um maior controle sobre a instalação de cookies, você pode instalar programas ou plug-ins no navegador, conhecidos como ferramentas de “Do Not Track”, que lhe permitem escolher os cookies que você deseja permitir.</Paragraph>
                  <Paragraph>Se você tiver qualquer dúvida sobre esta Política de Cookies, você pode <Link to='contatos'>entrar em contato</Link>.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Cookies | HostDime Brasil' />
      <PageTemplate
        title='Política de Cookies'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
